import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'bem-vindo',
    loadChildren: () => import('./bem-vindo/bem-vindo.module').then( m => m.BemVindoPageModule)
  },
  {
    path: 'painel',
    loadChildren: () => import('./painel/painel.module').then( m => m.PainelPageModule)
  },
  {
    path: 'bem-vindo',
    loadChildren: () => import('./bem-vindo/bem-vindo.module').then( m => m.BemVindoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./autenticacao/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'usuarios-form',
    loadChildren: () => import('./usuarios-form/usuarios-form.module').then( m => m.UsuariosFormPageModule)
  },
  {
    path: 'detalhes',
    loadChildren: () => import('./detalhes/detalhes.module').then( m => m.DetalhesPageModule)
  },
  {
    path: 'lista-confirmacao',
    loadChildren: () => import('./modais/lista-confirmacao/lista-confirmacao.module').then( m => m.ListaConfirmacaoPageModule)
  },
  {
    path: 'formulario-detalhes',
    loadChildren: () => import('./modais/formulario-detalhes/formulario-detalhes.module').then( m => m.FormularioDetalhesPageModule)
  },
  {
    path: 'novo-saude',
    loadChildren: () => import('./modais/novo-saude/novo-saude.module').then( m => m.NovoSaudePageModule)
  },
  {
    path: 'exm-vac-altera',
    loadChildren: () => import('./modais/exm-vac-altera/exm-vac-altera.module').then( m => m.ExmVacAlteraPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
