/* ATEN��O!
Esta aplica��o foi desenvolvida em car�ter de urgencia, devido a presen�a do Novo Corona V�rus,  causador da Covid-19, com o int�ito de rastrear a ocorr�ncia da doen�a no entre a equipe do Minist�rio P�blico do Rio de Janeiro.  Devido a urgencia, utilizou-se um c�digo que estava sendo utilizado para outros fins.  Por isso, um monte de arquivos e fun��es n�o s�o utilizadas nesta aplica��o, ainda que presentes no c�digo.
Adaptado por: Charles Robbs - 30-03-2019
*/

import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavController, MenuController  } from '@ionic/angular';
import { PassaObjetoService } from './services/passa-objeto.service';
import { Router } from '@angular/router';
import { AuthService } from './services/user/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public perfilAtual: any;
  authState: boolean = false;
  emailAtual: any;
  idAtual: any;
  nomeAtual: any;
  btUsuarios: boolean = false;
  usuario: any
  visitante = true
  rootPage:any = 'BemVindoPages';
  btVinculo = [];
  tabCraaisObj= [];
  carregando = false
  constructor(
    private authService: AuthService,
    private platform: Platform,
    public menuCtrl: MenuController,
    private passaObjetoService: PassaObjetoService,
    private navCtrl: NavController,
    private router : Router,
  ) {
    this.initializeApp();
      this.pegaUsuarioDados()

      var startDate = new Date();
      var endDate = new Date();
      endDate.setDate(startDate.getDate() - 100);
      var dataQuery = moment(endDate).format('YYYY-MM-DD');
     // allData.pegaSaude(dataQuery)
    //  allData.pegaAllUsers()
    //  this.btUsuarios = this.allData.btUsuarios

    this.btVinculo = [{ cor: "primary", checked: true, texto: "Todos", val: "Todos" },
    { cor: "dark", checked: false, texto: "Membros", val: "membro" },
    { cor: "dark", checked: false, texto: "Procuradores", val: "procurador" },
    { cor: "dark", checked: false, texto: "Promotores", val: "promotor" },
    { cor: "dark", checked: false, texto: "Servidor", val: "servidor" },
    { cor: "dark", checked: false, texto: "Estagiário", val: "estagiario" },
    { cor: "dark", checked: false, texto: "Terceirizado", val: "terceirizado" }]

    this.tabCraaisObj = [
      { cor: "primary", checked: true, texto: "Todos", val: "Todos" },
      { cor: "dark", checked: false, texto: "Rio de Janeiro", val: "CRAAI RIO DE JANEIRO" },
      { cor: "dark", checked: false, texto: "Duque de Caxias", val: "CRAAI DUQUE DE CAXIAS" },
      { cor: "dark", checked: false, texto: "Angra dos Reis", val: "CRAAI ANGRA DOS REIS" },
      { cor: "dark", checked: false, texto: "Cabo Frio", val: "CRAAI CABO FRIO" },
      { cor: "dark", checked: false, texto: "Teresópolis", val: "CRAAI TERESÓPOLIS" },
      { cor: "dark", checked: false, texto: "Macaé", val: "CRAAI MACAÉ" },
      { cor: "dark", checked: false, texto: "Petrópolis", val: "CRAAI PETRÓPOLIS" },
      { cor: "dark", checked: false, texto: "Barra do Piraí", val: "CRAAI BARRA DO PIRAÍ" },
      { cor: "dark", checked: false, texto: "Volta Redonda", val: "CRAAI VOLTA REDONDA" },
      { cor: "dark", checked: false, texto: "São Gonçalo", val: "CRAAI SÃO GONÇALO" },
      { cor: "dark", checked: false, texto: "Nova Friburgo", val: "CRAAI NOVA FRIBURGO" },
      { cor: "dark", checked: false, texto: "Campos", val: "CRAAI CAMPOS" },
      { cor: "dark", checked: false, texto: "Itaperuna", val: "CRAAI ITAPERUNA" },
      { cor: "dark", checked: false, texto: "Niterói", val: "CRAAI NITERÓI" },
      { cor: "dark", checked: false, texto: "Nova Iguaçu", val: "CRAAI NOVA IGUAÇU" },
      { cor: "dark", checked: false, texto: "R. Especial", val: "REGIÃO ESPECIAL" },
      { cor: "dark", checked: false, texto: "R. de subst.", val: "REGIÃO DE SUBTITUIÇÃO" }]

      this.passaObjetoService.setProfile('Todos')
      this.passaObjetoService.setCRAAI('Todos')
   
  }

  initializeApp() {
    var subs = this.authService.getUserData()
    .subscribe(data => {
       //  console.log("data", data)
      if (data !== undefined && data.toString() !== "UNAUTHORIZED") {
        this.usuario = data
        this.visitante = false
        //  console.log("data", data)
        this.router.navigate(['painel'])
      } else {
        this.router.navigate(['login'])
        return
      }
    },
      error => {
        this.router.navigate(['login'])
          console.log("error", error)
      },
      () => {
        //this gets called on completion, callback code comes here
        //  console.log("finish")

      }
    );
    
    this.passaObjetoService.watchCarregando().subscribe((value)=>{
      //  console.log(value)
      this.carregando = value
    }); 

    this.platform.ready().then(() => {
   //   this.navCtrl.navigateRoot('/agenda-lista');
   //this.navCtrl.navigateRoot('/agenda-lista');
    //  this.statusBar.styleDefault();
    // this.splashScreen.hide();

    this.router.navigate(['login'])
    });
     
  }



  pegaUsuarioDados() {
   this.authService.getUserData()  .subscribe(data => {
     //  console.log("data", data)
    if (data !== undefined && data.toString() !== "UNAUTHORIZED") {
      this.usuario = data
      this.visitante = false
      this.router.navigate(['painel'])
      // //  console.log("data", data)
    } else {
      this.router.navigate(['login'])
      return
    }
  },
    error => {
        console.log("error", error)
    },
    () => {
      //this gets called on completion, callback code comes here
      console.log("finish")

    }
  );

  }

  
  

  toggleMenu() {
    this.menuCtrl.toggle(); //Add this method to your button click function
  }


  openPerfil(){
    this.menuCtrl.toggle();
    this.navCtrl.navigateForward('/perfil-usuario/' + this.idAtual);
  }
  logout() {
    this.authService.logoutUsuario().subscribe(data => {
      let message
      if (data['success'] == true) {
        localStorage.removeItem('cvusr');
        this.setCookie('usrmat', null, 0)
        message = "Sucesso"
        window.location.reload()
      } else {
      }
    })
  }
  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  alteraVinculo(vinculo, i) {
    if (this.btVinculo[i]['checked'] === false) {
      this.btVinculo[i]['checked'] = true
      this.btVinculo[i]['cor'] = 'primary'
    } else {
      this.btVinculo[i]['checked'] = false
      this.btVinculo[i]['cor'] = 'dark'
    }
    this.btVinculo.forEach((info2) => {
      if (info2.checked = true) {
        info2.checked = false
        info2.cor = 'dark'
        this.btVinculo[i]['checked'] = true
        this.btVinculo[i]['cor'] = 'primary'
      }
    })
    this.passaObjetoService.setProfile(vinculo["val"])
  }

  alteraCraai(craai, i) {
    //  console.log(craai)
    if (this.tabCraaisObj[i]['checked'] === false) {
      this.tabCraaisObj[i]['checked'] = true
      this.tabCraaisObj[i]['cor'] = 'primary'
    } else {
      this.tabCraaisObj[i]['checked'] = false
      this.tabCraaisObj[i]['cor'] = 'dark'
    }
    this.tabCraaisObj.forEach((info2) => {
      if (info2.checked = true) {
        info2.checked = false
        info2.cor = 'dark'
        this.tabCraaisObj[i]['checked'] = true
        this.tabCraaisObj[i]['cor'] = 'primary'
      }
    })
    this.passaObjetoService.setCRAAI(craai["val"])
  }

  verEstatistica(dados) {
    this.passaObjetoService.setDestn({});
    this.passaObjetoService.setDestn(dados);
    this.navCtrl.navigateForward("estatistica");
}
}
