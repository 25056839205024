import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassaObjetoService {
  private destn: any;

  public profile$ = new BehaviorSubject<any | null>(null);
  public craai$ = new BehaviorSubject<any | null>(null);
  public carregando$ = new BehaviorSubject<any | null>(null);
  public reload$ = new BehaviorSubject<any | null>(null);
  constructor() { }

  public setDestn(destn) {
    this.destn = destn;
  }

  getDestn() {
    return this.destn;
  }

  public setProfile(profile) {
    this.profile$.next(profile)
  }
  watchProfile(): Observable<any | null> {
    return this.profile$;
  }

  public setCRAAI(craai) {
 //   console.log(craai)
    this.craai$.next(craai)
  }
  watchCRAAI(): Observable<any | null> {
    return this.craai$;
  }

  public setCarregando(carregando) {
 //   console.log(carregando)
    this.carregando$.next(carregando)
  }
  watchCarregando(): Observable<any | null> {
    return this.carregando$;
  }

  public setReload(reload) {
    console.log(reload)
    this.reload$.next(reload)
  }
  watchReload(): Observable<any | null> {
    return this.reload$;
  }
}
